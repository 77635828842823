// eslint-disable

import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import MaterialTableCustom from '../../../components/MaterialTableCustom'
import { MTableToolbar } from '@material-table/core'
import { makeStyles } from '@material-ui/core/styles'
// import DeliveryTableCarrierEdit from '../../../components/MaterialTableCustomComps/DeliveryTableCarrierEdit'
import DeliveryTableReceivedStatusEdit from '../../../components/MaterialTableCustomComps/DeliveryTableStatusEdit'
// import DeliveryTableTrackingLinkComp from '../../../components/MaterialTableCustomComps/DeliveryTableTrackingLink'
// import { trackingStatus } from '../../../components/MaterialTableCustomComps/DeliveryTableTypes'
import { useRecentDeliveries, useUpdateDelivery, useAddDelivery, useDeleteDelivery } from '../../../hooks/queries'
import { useQueryClient } from 'react-query'
import clsx from 'clsx';
import { ExportCsv } from '@material-table/exporters';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    fontSize: 15,
    height: 40,
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 10,
      minHeight: 0,
    },
  },
  tablePaper: {
    fontFamily: 'Nunito',
    marginTop: 20,
    borderRadius: 20,
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
    overflow: 'hidden',
    '& .MuiPaper-elevation2': {
      overflow: 'hidden',
      boxShadow: 'unset',
    },
    '& .MuiToolbar-gutters': {
      // paddingLeft: 10,
      minHeight: 70,
    },
    // '& .MuiTableCell-root': {
    //   padding: 0,
    // },
  },
}))

const DeliveriesTable = ({ parentPart, heading, refreshTableData }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const columns = [
    { title: 'PN', field: 'partNumber' },
    { title: 'Description', field: 'bomDescription', editable: false },
    {
      title: 'Delivery Date',
      field: 'dateOfRecord',
      type: 'date',
    },
    { title: 'Qty', field: 'quantity', type: 'numeric' },
    { title: 'Supplier', field: 'vendorName' },
    {
      title: 'Tracking #',
      field: 'trackingNumber',
      //   render: (rowData) => {
      //     if (!rowData.trackingNumber || rowData.trackingStatus === trackingStatus.ERROR) return rowData.trackingNumber
      //     else return <DeliveryTableTrackingLinkComp rowData={rowData} />
      //   },
    },
    // {
    //   title: 'Carrier',
    //   field: 'carrier',
    //   editComponent: DeliveryTableCarrierEdit,
    // },
    {
      title: 'Tracking Status',
      field: 'trackingStatus',
      editable: false,
    },
    {
      title: 'Received Status',
      field: 'receivedStatus',
      editComponent: DeliveryTableReceivedStatusEdit,
    },
  ]

  const queryResponse = useRecentDeliveries(parentPart)
  const { mutateAsync: addDelivery } = useAddDelivery()
  const { mutateAsync: updateDelivery } = useUpdateDelivery()
  const { mutateAsync: deleteDelivery } = useDeleteDelivery()

  let deliveriesData = queryResponse.data || [];
  const [deliveryData, setDeliveryData] = useState([]);

  useEffect(() => {
    if (deliveriesData?.length && Array.isArray(deliveriesData)) {
      const new_data = deliveriesData.sort((a, b) => new Date(a?.dateOfRecord) - new Date(b?.dateOfRecord)).map((d) => ({
        ...d,
        trackingNumber: d?.tracking?.trackingNumber,
        carrier: d?.tracking?.carrier,
        trackingStatus: d?.tracking?.status,
      }))
      setDeliveryData([...new_data])
    }
  }, [queryResponse?.data])

  if (queryResponse.isFetching === null) {
    return <>Loading... </>
  }

  const invalidateData = () => {
    queryClient.invalidateQueries('recentDeliveries')
    queryClient.invalidateQueries('CTBSummaryStats')
    queryClient.invalidateQueries('allDeliveries')
    queryClient.invalidateQueries('ctbTable')
  }

  return (
    <Paper className={clsx('mat-table-cont', classes.tablePaper)}>
      <MaterialTableCustom
        components={{
          // eslint-disable-next-line react/display-name
          Toolbar: (props) => (
            <div className={classes.toolbarWrapper}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        title={heading}
        columns={columns}
        data={deliveryData}
        rowStyle={{
          fontSize: '12px',
          paddingTop: 0,
          paddingBottom: 0,
          height: 0,
        }}
        headerStyle={{
          marginTop: -5,
          paddingTop: 0,
          paddingBottom: 5,
        }}
        cellStyle={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 135,
        }}
        options={{
          filtering: false,
          search: true,
          paging: false,
          actionsColumnIndex: 10,
          maxBodyHeight: '600px',
          exportMenu: [{
            label: 'Export CSV',
            exportFunc: (cols, datas) => {
              return ExportCsv(cols, datas, `recent_deliveries_${parentPart.partNumber}`)
            }
          }]
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addDelivery(newData)
            invalidateData()
          },
          onRowUpdate: async (newData) => {
            await updateDelivery(newData);
            await refreshTableData();
          },
          onRowDelete: async (oldData) => {
            await deleteDelivery({ id: oldData.id })
            invalidateData()
          },
        }}
      />
    </Paper>
  )
}

export default DeliveriesTable
